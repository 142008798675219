<template>
  <b-card
    v-if="data"
    text-variant="left"
    class="card card-survey"
  >
    <b-avatar
      variant="primary"
      size="60"
      class="shadow aequip-card-top-right mb-2"
    >
      <feather-icon
        size="28"
        icon="ClipboardIcon"
      />
    </b-avatar>
    <b-card-header class="aequip-card-header">
      <h1 class="mb-sm-0 text-b-yellow">
        {{ data.surveyName }}
      </h1>

    </b-card-header>
    <b-card-body>
      <div>
        <b-card-text class="m-right">
          <span
            style=" font-size: 2rem"
          >{{ data.percentageCompleted }}%</span> of people have completed the survey. Survey available from <b>{{ data.startDate }}</b> to <b>{{ data.endDate }}</b>.
        </b-card-text>
        <b-progress
          :max="100"
          class="progress-bar-info"
        >
          <b-progress-bar
            :value="data.percentageCompleted"
            variant="primary"
            striped
            class="progress-bar-primary"
          />
        </b-progress>
        <b-button
          v-if="!data.surveyEnded"
          size="sm"
          pill
          :disabled="true"
          href="#"
          variant="outline-secondary"
        >
          In Progress
        </b-button>
        <b-button
          v-if="data.surveyEnded"
          size="sm"
          pill
          :disabled="true"
          href="#"
          variant="outline-success"
        >
          Completed
        </b-button>
      </div>
    </b-card-body>
    <div
      class="aequip-button-right-block"
    >
      <!--
                v-if="data.completedUsers === data.totalUsers && !data.surveyEnded"
-->

      <b-button
        v-if="data.completedUsers > 0 && !data.surveyEnded"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="aequip-button-right btn-success"
        @click="endSurveyEarly"
      >
        End Survey Early
      </b-button>
      <b-button
        v-if="!(data.completedUsers === data.totalUsers && !data.surveyEnded) && !data.surveyEnded"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="aequip-button-right btn-primary"
        @click="confirmSendSurveyReminders"
      >
        Send Survey Reminders
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BCardText, BButton, BCardBody, BCardHeader, BProgressBar, BProgress,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  components: {
    BCard,
    BAvatar,
    BCardText,
    BButton,
    BCardBody,
    BCardHeader,
    BProgressBar,
    BProgress,
  },
  directives: {
    Ripple,
  },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      data: this.details,
    }
  },
  methods: {
    endSurveyEarly() {
      const { surveyId } = this.data
      this.$swal({
        title: 'End Survey',
        text: 'End Survey early and process results now?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sounds good!',
        cancelButtonText: 'Maybe later...',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        showLoaderOnConfirm: true,
        buttonsStyling: false,
        preConfirm: () => axios
          .put('/survey/end-early', { id: surveyId })
          .then(response => {
            this.data.surveyEnded = true
            this.data.endDate = new Date().toLocaleString('default', {
              weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short',
            })
            console.log(response)
            this.$swal({
              title: 'Success!',
              text: 'Results of the Survey will be available shortly!',
              icon: 'success',
              allowOutsideClick: true,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch(error => {
            console.log(error)
            this.$swal({
              title: 'Error!',
              text: ' Oops! Looks like something didnt go to plan, Sorry about that. Check if the survey has already ended, else please try again, or contact Support',
              icon: 'error',
              allowOutsideClick: true,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }),
      })
    },
    confirmSendSurveyReminders() {
      const { surveyId } = this.data
      this.$swal({
        title: 'Email Reminders',
        text: 'Send reminders to all who have not yet completed the survey?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sounds good!',
        cancelButtonText: 'Maybe later...',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        showLoaderOnConfirm: true,
        buttonsStyling: false,
        preConfirm: () => axios
          .put('/survey/reminder', { id: surveyId })
          .then(response => {
            console.log(response)
            this.$swal({
              title: 'Success!',
              text: 'Reminders will be sent to all who have not yet completed the survey shortly!',
              icon: 'success',
              allowOutsideClick: true,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch(error => {
            console.log(error)
            this.$swal({
              title: 'Error!',
              text: ' Oops! Looks like something didnt go to plan, Sorry about that. Check if the survey has already ended, else please try again, or contact Support',
              icon: 'error',
              allowOutsideClick: true,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }),
      })
    },
  },
}
</script>
