<template>
  <b-card
    v-if="data"
    text-variant="left"
    class="card card-actions"
  >
    <b-img
      :src="require('@/assets/images/illustration/email.svg')"
      alt="Actions Pic"
      class="aequip-card-bottom-right"
    />
    <b-avatar
      variant="red"
      size="60"
      class="shadow aequip-card-top-right mb-2"
    >
      <feather-icon
        size="28"
        icon="CheckSquareIcon"
      />
    </b-avatar>
    <b-card-header class="aequip-card-header">
      <h1 class="mb-sm-0 text-b-red">
        Actions
      </h1>
    </b-card-header>
    <b-card-body>
      <b-card-text class="m-right aequip-card-text w-75">
        <span v-html-safe="getText" />
      </b-card-text>
    </b-card-body>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="aequip-card-bottom-left btn-red"
      @click="$router.push({ name: 'actions' })"
    >
      Take Action
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BCardText, BImg, BButton, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BAvatar,
    BCardText,
    BImg,
    BButton,
    BCardBody,
    BCardHeader,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getText() {
      const actionText = [
        '',
        'Send out your first survey to start making a difference to your company wellbeing and workplace inclusiveness.',
        'Once we have analysed your scores, we will provide a series of evidence-backed prompts which are tailored to the needs of <b>[company_name]</b> and are focused on the areas that can have the greatest impact.',
        'These actions will provide the biggest impact on your BIG 5 scores and could save <b>[company_name]</b> money by reducing absenteeism, presenteeism and staff turnover and increasing employee wellbeing and workplace inclusivity.',
      ]
      const userdata = JSON.parse(localStorage.getItem('userData'))
      const { stage } = this.data
      let text = actionText[stage]
      text = text.replace('[company_name]', userdata.companyName)
      return text.replace('[first_name]', userdata.given_name)
    },
  },
}
</script>
