<template>
  <b-card
    v-if="data"
    text-variant="left"
    class="card card-insights"
  >
    <b-img
      :src="require('@/assets/images/illustration/marketing.svg')"
      alt="Insights Pic"
      class="aequip-card-bottom-right"
    />
    <b-avatar
      variant="purple"
      size="60"
      class="shadow aequip-card-top-right mb-2"
    >
      <feather-icon
        size="28"
        icon="MapIcon"
      />
    </b-avatar>
    <b-card-header class="aequip-card-header">
      <h1 class="mb-sm-0 text-b-purple">
        Insights
      </h1>
    </b-card-header>
    <b-card-body>
      <b-card-text class="m-right aequip-card-text w-75">
        <span v-html-safe="getText" />
      </b-card-text>
    </b-card-body>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="aequip-card-bottom-left btn-purple"
      @click="$router.push({ name: 'insights' })"
    >
      View Insights
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BCardText, BButton, BImg, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BAvatar,
    BCardText,
    BImg,
    BButton,
    BCardBody,
    BCardHeader,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getText() {
      const insightText = [
        '',
        'Hi <b>[first_name]</b>,<br/>Welcome to your Aequip Dashboard! Get ready to:<ul><li>Build Organisational Wellbeing</li><li>Drive Productivity</li><li>Unleash Innovation</li><li>Measure What Matters</li><li>Promote Inclusion and Belonging</li></ul>',
        'Congratulations <b>[first_name]</b>, your inclusion index survey has been sent. We are gathering responses and calculating scores.',
        'Hi <b>[first_name]</b>,<br/><b>[company_name]</b> is doing best in [bestScore] in the workplace!<br/>[lowestScores] are looking a little low with room for improvement.<br/>Head to the Insights section to gain a deeper understanding of your BIG 5 scores<br/>',
      ]

      const userdata = JSON.parse(localStorage.getItem('userData'))
      const { stage } = this.data
      let text = insightText[stage]
      text = text.replace('[company_name]', userdata.companyName)
      text = text.replace('[bestScore]', this.data.bestScore)
      text = text.replace('[lowestScores]', this.data.lowestScores)

      return text.replace('[first_name]', userdata.given_name)
    },
  },
}
</script>
